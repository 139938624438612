import React from "react";
import { Link } from "react-router-dom";
import { pw } from "../components/EditPage";
import { fetchText } from "../index.js";

export function save() {
    function getKeyAndValueByIndex(map, index) {
        if (index < 0 || index >= map.size) {
          throw new Error('Index out of bounds');
        }
      
        let currentIndex = 0;
        for (let [key, value] of map.entries()) {
          if (currentIndex === index) {
            return { key, value };
          }
          currentIndex++;
        }
    }

    let map = new Map();

    let infoover = document.getElementById("infoover1")?.value;
    let infodown = document.getElementById("infodown1")?.value;
    let aboutustext = document.getElementById("aboutustext1")?.value;
    let textup = document.getElementById("textup")?.value;
    let textdown = document.getElementById("textdown")?.value;
    let kontaktheadline = document.getElementById("kontaktheadline")?.value;
    let kontakttextover = document.getElementById("kontakttextover")?.value;
    let subtitlekontakt = document.getElementById("subtitlekontakt")?.value;
    let kontaktöff = document.getElementById("kontaktöff")?.value;
    let kntsub = document.getElementById("kntsub")?.value;
    let subknt = document.getElementById("subknt")?.value;
    let aboutustop = document.getElementById("aboutustop")?.value;
    let aboutustextside = document.getElementById("aboutustextside")?.value;

    if (infoover != null) {
        map.set("infoover", infoover);
    }
    if (infodown != null) {
        map.set("infodown", infodown);
    }
    if (aboutustext != null) {
        map.set("aboutustext", aboutustext);
    }
    if (textup != null) {
        map.set("textup", textup);
    }
    if (textdown != null) {
        map.set("textdown", textdown);
    }
    if (kontaktheadline != null) {
        map.set("kontaktheadline", kontaktheadline);
    }
    if (kontakttextover != null) {
        map.set("kontakttextover", kontakttextover);
    }
    if (subtitlekontakt != null) {
        map.set("subtitlekontakt", subtitlekontakt);
    }
    if (kontaktöff != null) {
        map.set("kontaktöff", kontaktöff);
    }
    if (kntsub != null) {
        map.set("kntsub", kntsub);
    }
    if (subknt != null) {
        map.set("subknt", subknt);
    }
    if (aboutustop != null) {
        map.set("aboutustop", aboutustop);
    }
    if (aboutustextside != null) {
        map.set("aboutustextside", aboutustextside);
    }
    let a = 0;
    for (let i = 0; i < map.size; i++) {
        let res = getKeyAndValueByIndex(map, i);
        if (res.value != ""){
            a++;
        }
    }
    let s = { method: "setlist", passkey: pw, listheight: a };
    let b = 0;
    for (let i = 0; i < map.size; i++) {
        let res = getKeyAndValueByIndex(map, i);
        console.log(res.key + " " + res.value)
        if (res.value != ""){
            s[b + ".param"] = res.key;
            s[b + ".value"] = res.value;
            b++;
        }
    }

    fetchText(s);
    alert("Done!");
}
