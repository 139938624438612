import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Header from "../Header";
import SubHeaderImage from "../../subeditcomponents/SubHeaderImage";
import TextBox from "../../subeditcomponents/TextBox";
import Footer from "../Footer";
import KontaktBox from "../../subeditcomponents/KontaktBox";

import { fetchText } from "../../index.js";
import EditAboutUs from "../../subeditcomponents/EditAboutUs/index.jsx";
import { save } from "../../subeditcomponents/save.js";
import PersonalComponent from "../../subeditcomponents/PersonalComponent/index.jsx";
import { useNavigate } from 'react-router-dom';
let passed = false;
export let pw = undefined;
const EditPage = () => {
    const navigate = useNavigate();
    const saven = () => {
        save();
        navigate("/");
        window.location.reload();
    }


    if (!passed) {
    let password = prompt("Please enter your password");
    let p = fetchText({method : "checkpw", passkey : password});
    if (p != "OK"){
      alert("wrong password");
      return (<div>te</div>);
    }
    passed = true;
    pw = password;
    }
    return (
            <div>        
            <Header/>
            <SubHeaderImage/>
            <TextBox/>
            <PersonalComponent/>
            <KontaktBox/>
            <EditAboutUs/>
            <div className="i">
                <button className="dropdownbutton" onClick={() => saven()}>save</button>
            </div>
            <div className="placeholder"></div>
            <Footer/>
            </div>
    );
    
}
export default EditPage;








