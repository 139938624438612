import Footer from "../Footer";
import Header from "../Header";
import "./index.css";
const ErrorPage = () => {
    return (
        <div>
            <Header/>
                <div className="container">
                    <h1>404</h1>
                    <p>Not Found!</p>
                </div>
            <Footer/>
        </div>


    );




}
export default ErrorPage;