import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Header from "../Header";
import Footer from "../Footer";
import {textes} from "../../index.js";
import TextComponent from "../../subcomponents/TextComponent.js";


const Aboutus = () => {
    let i1 = textes["aboutustop"];
    let i2 = textes["aboutustextside"];
    
    return (
        <div>       
             <Header/>
             <div>
            <div className = "back">
                <div className="aboutcontainer">
                    <div className="placeholder"></div>
                    <div className="info" id = "infoover">
                        <TextComponent textWithLineBreaks={i1} />
                    
                    </div>
                    <div className="placeholder1"></div>
                    <div id="aboutustext" className="aboutustext"><TextComponent textWithLineBreaks={i2} /></div>
                </div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>


                </div>
            </div>
             <Footer/>
        </div>




    );
}
export default Aboutus;