
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Aboutus from "./components/Aboutus";
import ErrorPage from "./components/ErrorPage";
import EditPage from "./components/EditPage";
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";
function App() {
  return (
    <>
     <BrowserRouter>
        <Routes>
          <Route path="/">
          <Route index element={<Main />} />
          <Route path="/aboutus" element={<Aboutus/>}/>
          <Route path="/edit" element={<EditPage/>}/>
          <Route path="/datenschutz" element={<Datenschutz/>}/>
          <Route path="/impressum" element={<Impressum/>}/>
          <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
