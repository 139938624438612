import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import "./index.css";
const Impressum = () => {
    return ( 
        <div>
            <Header />
                <div className="impessumscontainer">
                <h2>Balk GmbH</h2>
    <p>
        Kandlstraße 4<br />
        93098 Mintraching
    </p>
    <p>
        Geschäftsführer: Richard Balk<br />
        Telefon: 0049 9406 28208-0<br />
        E-Mail: <a href="mailto:service@balkgmbh.de">service@balkgmbh.de</a>
    </p>
    <p>
        Amtsgericht Regensburg: HRB 13271<br />
        §34d/§34f Gewerbeordnung<br />
        Berufsbezeichnung: Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 Gewerbeordnung, Bundesrepublik Deutschland
    </p>
    <p>
        Die Eintragung im Vermittlerregister (<a href="http://www.vermittlerregister.info" target="_blank">www.vermittlerregister.info</a>) als Versicherungsmakler und als Finanzanlagen¬vermittler nach § 34f Abs. 1 S. 1 GewO kann unter folgenden Registrierungs-Nummern abgerufen werden:<br />
        D-ESNH-THJV9-58<br />
        D-F-155-ZYLZ-87
    </p>

    <p>Angaben zur Berufshaftpflichtversicherung</p>
    <p>
        Name und Sitz des Versicherers:<br />
        Vermögensschadenhaftpflichtversicherung bei der Ergo Versicherung AG<br />
        Victoriaplatz 2<br />
        40198 Düsseldorf
    </p>
    <p>
        Aufsichtsbehörde und zuständige Behörde für die Erlaubnis:<br />
        Industrie- und Handelskammer für München und Oberbayern<br />
        Max-Joseph-Str. 2<br />
        80333 München<br />
        Telefon: +49 89 5116-0<br />
        <a href="http://www.ihk-muenchen.de" target="_blank">http://www.ihk-muenchen.de</a>
    </p>
    <p>
        Die Eintragung kann wie folgt überprüft werden:<br />
        DIHK | Deutsche Industrie- und Handelskammer<br />
        Breite Straße 29, 10178 Berlin<br />
        Telefon: 0180-600-585-0 *<br />
        <a href="http://www.vermittlerregister.info" target="_blank">www.vermittlerregister.info</a><br />
        * 0,20 €/Anruf
    </p>
    <p>
        Gemäß § 36 VSBG und § 17 Abs. 4 VersVermV teilen wir mit, dass wir verpflichtet und bereit sind an einem Streitbeilegungsverfahren teilzunehmen. Folgende Schlichtungsstellen können angerufen werden:<br />
        Versicherungsombudsmann e.V.<br />
        Postfach 08 06 32<br />
        10006 Berlin<br />
        <a href="http://www.versicherungsombudsmann.de" target="_blank">www.versicherungsombudsmann.de</a>
    </p>
    <p>
        Ombudsmann Private Kranken- und Pflegeversicherung<br />
        Postfach 06 02 22<br />
        10052 Berlin<br />
        <a href="http://www.pkv-ombudsmann.de" target="_blank">www.pkv-ombudsmann.de</a>
    </p>
    <p>
        Europäische Kommission<br />
        Online-Streitbeilegungsplattform (OS-Plattform)<br />
        <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a>
    </p>

    <h2>Berufsrechtliche Regelungen</h2>
    <p>
        • § 34 d Gewerbeordnung (GewO)<br />
        • §§ 59 - 68 Versicherungsvertragsgesetz (VVG)<br />
        • Versicherungsvermittlerverordnung (VersVermV)
    </p>
    <p>
        Berufsrechtliche Regelungen für Finanzanlagenvermittler:<br />
        • § 34f Gewerbeordnung (GewO)<br />
        • Verordnung über die Finanzanlagenvermittlung (FinVermV)
    </p>
    <p>
        Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="http://www.gesetze-im-internet.de" target="_blank">www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.
    </p>

    <h2>Beschwerdemanagement</h2>
    <p>
        Beschwerden sind in Textform an die Geschäftsleitung zu richten und werden im Rahmen unseres Beschwerdemanagements unverzüglich bearbeitet.
    </p>

            
                </div>
            <Footer />
        </div>
    );
}

export default Impressum;