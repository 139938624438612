import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import {textes} from "../../index.js";
const SubHeaderImage = () => {
    const scrolldown = () => {
        console.log("scrolldown");
        document.querySelector('.carimagesection').scrollIntoView({ 
            behavior: 'smooth' 
          });

    };
    let textup = textes["textup"];
    let textdown = textes["textdown"];
    console.log("Variables : " + textup + " " + textdown);
    return (
        <div className="imagecontainer1">
        <div className="textcontainer">
            <input id = "textup" className="textup" placeholder={textup}/>
            <input id = "textdown" className="textdown" placeholder={textdown}/>
            <button onClick= {() => scrolldown()}  className="dropdownbutton">Kontakt</button>
        </div>

    </div>
    );
}
export default SubHeaderImage;