import "./index.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { textes } from "../../index.js";
const EditAboutUs = () => {
    let i1 = textes["aboutustop"];
    let i2 = textes["aboutustextside"];
    return (
        <div>
            <h1>Edit about us!</h1>

            <div>
            <div className = "back">
                <div className="aboutcontainer">
                    <div className="placeholder"></div>
                    <input className="info" id = "aboutustop" placeholder={i1}/>
                    <div className="placeholder1"></div>
                    <textarea className="info" id = "aboutustextside">{i2}</textarea>
                </div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                </div>
            </div>
    
        </div>


    );


}
export default EditAboutUs;