import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { textes } from "../../index.js"; 

const KontaktBox = () => {
    let headline = textes["kontaktheadline"]
    let kontakttextover = textes["kontakttextover"];
    let subtitlekontakt = textes["subtitlekontakt"];
    let kontaktöff = textes["kontaktöff"];
    let kntsub = textes["kntsub"];
    let subknt = textes["subknt"];
    return (
        <div className="carimagesection" id="carimagesection">
        <div className="placeholder"></div>
        <div className="flytext">
        <div className="withbuttoncontact">
            <input id="kontaktheadline" className="kontaktheadline5" placeholder={headline}/>
            <div></div>
            <textarea id = "kontakttextover" className="kontaktheadline5"  >{kontakttextover}</textarea>
			<div className="placeholder2"></div>
            <input id="subtitlekontakt" className="kontaktheadline5" placeholder={subtitlekontakt}/>
            <div></div>
            <textarea id = "kontaktöff" className="kontaktheadline5" >{kontaktöff}</textarea>
			<div className="placeholder2"></div>
            <input className="kontaktheadline5" id = "kntsub" placeholder={kntsub}/>
            <div></div>
            <textarea id = "subknt" className="kontaktheadline5" >{subknt}</textarea>
            <div className="placeholder"></div>
            <a href="mailto:service@balkgmbh.de" className="emailwritebutton">Email Schreiben</a>
        </div>
      </div>
        <div className="placeholder"></div>
    </div>
    );
}
export default KontaktBox;