import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Header from "../Header";
import SubHeaderImage from "../../subcomponents/SubHeaderImage";
import TextBox from "../../subcomponents/TextBox";
import Footer from "../Footer";
import PersonalComponent from "../../subcomponents/PersonalComponent";
import KontaktBox from "../../subcomponents/KontaktBox";
const Main = () => {
    return (
        <div>        
        <Header/>
        <SubHeaderImage/>
        <TextBox/>
        <PersonalComponent/>
        <KontaktBox/>
        <Footer/>
        </div>

    );
}
export default Main;