import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import {textes} from "../../index.js";
import TextComponent from "../TextComponent.js";
const TextBox = () => {
    let infoover = textes["infoover"];
    let infodown = textes["infodown"];
    let aboutustext = textes["aboutustext"];

    return (
        <div>
            <div className="placeholder"></div>
            <div className = "back">
                <div className="aboutcontainer">
                    <div className="info" id = "infoover">{infoover}</div>
                    <div className="aboutus" id = "infodown">{infodown}</div>
                    <div className="placeholder1"></div>
                    <div id="aboutustext" style={{fontSize: "20px"}} className="aboutustext"><TextComponent textWithLineBreaks={aboutustext}/></div>
                </div>
        <div className="placeholder"></div>
    </div>
    </div>
    );
}
export default TextBox;
