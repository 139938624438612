import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./index.css";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    const scrolldown = () => {
        navigate('/');
        setTimeout(() => {
            document.querySelector('.carimagesection').scrollIntoView({ 
                behavior: 'smooth' 
              });
        }, 500);
    };
    const scrolldowntoserv = () => {
        navigate('/');
        console.log("scrolldown");
          setTimeout(() => {
            document.querySelector('.balkabout').scrollIntoView({ 
                behavior: 'smooth' 
              });
        }, 500);
    };

    return (
        <header>
        <div className="header-top">
            <div className="logo">
            <Link to="/">
                <img width="200" src="logo.jpg" alt="Logo"/>
            </Link>
            </div>
            <nav>

                <NavLink to="/aboutus"><a>Über uns</a></NavLink>
                <a onClick={() => scrolldowntoserv()}>Team</a>
                <a onClick={() => scrolldown()}>Kontakt</a>
            
            </nav>
        </div>
    </header>


    );
}
export default Header;