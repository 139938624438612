import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import {textes} from "../../index.js";

const TextBox = () => {
    let infoover = textes["infoover"];
    let infodown = textes["infodown"];
    let aboutustext = textes["aboutustext"];

    return (
        <div>
            <div className="placeholder"></div>
            <div className = "back">
                <div className="aboutcontainer">
                    <div className="info" id = "infoover">
                    <input placeholder={infoover} id = "infoover1"/>
                    </div>
                    <div className="aboutus" id = "infodown">
                    <input placeholder={infodown} id = "infodown1"/>
                    </div>
                    <div className="placeholder1"></div>
                    <div id="aboutustext" className="aboutustext">
                        <textarea id = "aboutustext1">{aboutustext}</textarea>
                        </div>
                </div>
        <div className="placeholder"></div>
    </div>
        </div>
    );
}
export default TextBox;
