import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

const Footer = () => {
    return (
        <footer>
        <div className="footer-container">
            <div className="footer-logo">Richard Balk</div>
            <ul className="footer-links">
                <li><Link to="/aboutus">Über Uns</Link></li>
                <li><Link to="/impressum">Impressum</Link></li>
                <li><Link to="/datenschutz">Datenschutz</Link></li>
                <li><Link to="/edit">Bearbeiten</Link></li>
            </ul>
            <ul className="footer-social">
            </ul>
        </div>
        <div className="footer-copyright">
            &copy; 2024 Richard Balk. All rights reserved.
        </div>
    </footer>
    );
}
export default Footer;