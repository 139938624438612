import React from 'react';
import "./index.css";
const TextComponent = ({ textWithLineBreaks }) => {
  const paragraphs = textWithLineBreaks.split('\n');

  return (
    <div className="text-container">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="paragraph">{paragraph}</p>
      ))}
    </div>
  );
};


export default TextComponent;