import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import "./index.css";
const Datenschutz = () => {
    return ( 
        <div>
            <Header />
            <div className="container">
        <h1>Datenschutzerklärung</h1>
        <br />
        <p>
            Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. 
            Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
        </p>
        <br />
        <h2>1. Keine Speicherung von personenbezogenen Daten</h2>
        <p>
            Wir speichern keinerlei personenbezogene Daten unserer Website-Besucher. Es werden weder IP-Adressen noch andere Informationen erhoben oder gespeichert,
            die Rückschlüsse auf Ihre Identität zulassen. Dies bedeutet, dass Ihr Besuch auf unserer Website vollständig anonym bleibt und wir keinerlei Daten sammeln,
            die mit Ihrer Person in Verbindung gebracht werden könnten.
        </p>
        <br />
        <h2>2. Eigentum an Bildern</h2>
        <p>
            Alle auf dieser Website verwendeten Bilder sind unser Eigentum oder wir verfügen über die erforderlichen Nutzungsrechte. Eine Verwendung der Bilder durch Dritte ist ohne unsere ausdrückliche schriftliche Zustimmung nicht gestattet. Jegliche unautorisierte Nutzung, Vervielfältigung oder Verbreitung der Bilder stellt eine Verletzung unserer Rechte dar und kann rechtliche Konsequenzen nach sich ziehen. Sollten Sie Interesse an der Nutzung unserer Bilder haben, kontaktieren Sie uns bitte über die im Impressum angegebenen Kontaktdaten.
        </p>
        <br />
        <h2>3. Einsatz von Cookies</h2>
        <p>
            Wir setzen keine Cookies oder ähnliche Technologien ein, die dazu dienen, das Nutzerverhalten zu analysieren oder zu tracken. Unsere Website ist so konzipiert, dass sie keinerlei Tracking-Mechanismen verwendet, um sicherzustellen, dass Ihre Privatsphäre stets gewahrt bleibt. Dies bedeutet, dass keine Informationen über Ihr Surfverhalten, Ihre Präferenzen oder andere Aktivitäten gespeichert oder ausgewertet werden.
        </p>
        <br />
        <h2>4. Keine Weitergabe von Daten</h2>
        <p>
            Da wir keine personenbezogenen Daten von Ihnen erheben, findet auch keine Weitergabe Ihrer Daten an Dritte statt. Wir verpflichten uns, Ihre Privatsphäre zu schützen und sicherzustellen, dass keinerlei Informationen über unsere Website-Besucher an externe Parteien weitergeleitet werden.
        </p>
        <br />
        <h2>5. Ihre Rechte</h2>
        <p>
            Auch wenn wir keine personenbezogenen Daten speichern, haben Sie selbstverständlich jederzeit das Recht auf Auskunft, Berichtigung, Löschung oder Sperrung Ihrer gespeicherten Daten. Sollten Sie Fragen oder Anliegen bezüglich Ihrer Daten haben, kontaktieren Sie uns bitte über die im Impressum angegebenen Kontaktdaten. Wir werden Ihr Anliegen umgehend und umfassend bearbeiten.
        </p>
        <br />
        <h2>6. Änderungen dieser Datenschutzerklärung</h2>
        <p>
            Diese Datenschutzerklärung kann gelegentlich aktualisiert werden, um rechtlichen, technischen oder geschäftlichen Änderungen Rechnung zu tragen. Wir empfehlen Ihnen daher, diese Erklärung regelmäßig zu überprüfen. Die jeweils aktuelle Version unserer Datenschutzerklärung ist jederzeit auf unserer Website verfügbar. Sollten wesentliche Änderungen vorgenommen werden, informieren wir Sie durch einen Hinweis auf unserer Website.
        </p>
        <br />
        <h2>7. Sicherheit Ihrer Daten</h2>
        <p>
            Obwohl wir keine personenbezogenen Daten speichern, legen wir großen Wert auf die Sicherheit unserer Website und treffen alle erforderlichen Maßnahmen, um unbefugten Zugriff, Verlust oder Missbrauch der Informationen zu verhindern. Unsere Sicherheitsmaßnahmen werden regelmäßig überprüft und bei Bedarf aktualisiert, um den aktuellen technischen Standards zu entsprechen.
        </p>
        <br />
        <h2>8. Datenverarbeitung durch Dritte</h2>
        <p>
            Zur Sicherstellung des Betriebs und der Sicherheit unserer Website arbeiten wir mit Drittanbietern zusammen, die in unserem Auftrag Dienstleistungen erbringen (z. B. Hosting-Dienstleister). Diese Drittanbieter haben keinen Zugriff auf personenbezogene Daten unserer Website-Besucher und sind vertraglich verpflichtet, unsere Datenschutzstandards einzuhalten.
        </p>
        <br />
        <h2>9. Links zu anderen Websites</h2>
        <p>
            Unsere Website kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Bitte beachten Sie, dass wir keine Kontrolle über den Inhalt und die Datenschutzpraktiken dieser Websites haben. Wir empfehlen Ihnen, die Datenschutzbestimmungen jeder Website, die Sie besuchen, sorgfältig zu lesen.
        </p>
        <br />
        <h2>10. Einhaltung gesetzlicher Bestimmungen</h2>
        <p>
            Wir verpflichten uns, die geltenden Datenschutzgesetze und -vorschriften einzuhalten. Dies umfasst unter anderem die Datenschutz-Grundverordnung (DSGVO) der Europäischen Union sowie alle anderen relevanten nationalen und internationalen Datenschutzgesetze. Sollte es zu einem Konflikt zwischen dieser Datenschutzerklärung und den gesetzlichen Bestimmungen kommen, haben die gesetzlichen Bestimmungen Vorrang.
        </p>
        <br />
        <h2>11. Hosting und Server-Log-Dateien</h2>
        <p>
            Unser Hosting-Provider erhebt und speichert automatisch Informationen in sogenannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
            <ul>
                <li>Browsertyp und Browserversion</li>
                <li>Verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
            </ul>
            Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
        </p>
        <br />
        <h2>12. Einsatz von Social Media Plugins</h2>
        <p>
            Unsere Website verwendet keine Social Media Plugins, die Ihre Daten an soziale Netzwerke weitergeben könnten. Sollte sich dies in Zukunft ändern, werden wir Sie in dieser Datenschutzerklärung umfassend darüber informieren.
        </p>
        <br />
        <h2>13. Plugins und Tools</h2>
        <p>
            Unsere Website verwendet keine externen Plugins oder Tools, die Ihre Daten erheben oder weiterleiten könnten. Sollte sich dies in Zukunft ändern, werden wir Sie in dieser Datenschutzerklärung umfassend darüber informieren.
        </p>
        <br />
        <h2>14. Minderjährigenschutz</h2>
        <p>
            Unser Angebot richtet sich grundsätzlich an Erwachsene. Personen unter 16 Jahren dürfen ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. Wir fordern keine personenbezogenen Daten von Kindern und Jugendlichen an, sammeln diese nicht und geben sie nicht an Dritte weiter.
        </p>
        <br />
        <h2>15. Kontakt</h2>
        <p>
            Für Fragen zum Datenschutz oder weitere Informationen, wie wir Ihre Daten schützen, kontaktieren Sie uns bitte unter den im Impressum angegebenen Kontaktdaten. Wir stehen Ihnen gerne zur Verfügung, um Ihre Fragen zu beantworten und Ihnen weitere Auskünfte zu geben. Ihre Zufriedenheit und Ihr Vertrauen sind uns wichtig, und wir setzen alles daran, Ihre Daten bestmöglich zu schützen.
        </p>
        <br />
        <div class="contact-info">
            <h2>Kontaktinformationen</h2>
            <p>
                Balk GmbH<br />
                Kandlstraße 4<br />
                93098 Mintraching<br />
                Geschäftsführer: Richard Balk<br />
                Telefon: 0049 9406 28208-0<br />
                E-Mail: <a href="mailto:service@balkgmbh.de">service@balkgmbh.de</a><br />
                Amtsgericht Regensburg: HRB 13271<br />
                §34d/§34f Gewerbeordnung<br />
                Berufsbezeichnung: Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 Gewerbeordnung, Bundesrepublik Deutschland<br />
                Die Eintragung im Vermittlerregister (www.vermittlerregister.info) als Versicherungsmakler und als Finanzanlagenvermittler nach § 34f Abs. 1 S. 1 GewO kann unter folgenden Registrierungs-Nummern abgerufen werden:<br />
                D-ESNH-THJV9-58<br />
                D-F-155-ZYLZ-87<br />
            </p>
            <h2>Angaben zur Berufshaftpflichtversicherung</h2>
            <p>
                Name und Sitz des Versicherers:<br />
                Vermögensschadenhaftpflichtversicherung bei der Ergo Versicherung AG<br />
                Victoriaplatz 2<br />
                40198 Düsseldorf<br />
            </p>
            <h2>Aufsichtsbehörde und zuständige Behörde für die Erlaubnis</h2>
            <p>
                Industrie- und Handelskammer für München und Oberbayern<br />
                Max-Joseph-Str. 2<br />
                80333 München<br />
                Telefon: +49 89 5116-0<br />
                <a href="http://www.ihk-muenchen.de">http://www.ihk-muenchen.de</a><br />
            </p>
            <h2>Die Eintragung kann wie folgt überprüft werden</h2>
            <p>
                DIHK | Deutsche Industrie- und Handelskammer<br />
                Breite Straße 29, 10178 Berlin<br />
                Telefon: 0180-600-585-0 * <a href="http://www.vermittlerregister.info">www.vermittlerregister.info</a><br />
                * 0,20 €/Anruf<br />
            </p>
            <h2>Schlichtungsstellen</h2>
            <p>
                Gemäß § 36 VSBG und § 17 Abs. 4 VersVermV teilen wir mit, dass wir verpflichtet und bereit sind, an einem Streitbeilegungsverfahren teilzunehmen. Folgende Schlichtungsstellen können angerufen werden:<br />
                Versicherungsombudsmann e.V.<br />
                Postfach 08 06 32<br />
                10006 Berlin<br />
                <a href="http://www.versicherungsombudsmann.de">www.versicherungsombudsmann.de</a><br />
                Ombudsmann Private Kranken- und Pflegeversicherung<br />
                Postfach 06 02 22<br />
                10052 Berlin<br />
                <a href="http://www.pkv-ombudsmann.de">www.pkv-ombudsmann.de</a><br />
                Europäische Kommission<br />
                Online-Streitbeilegungsplattform (OS-Plattform)<br />
                <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a><br />
            </p>
            <h2>Berufsrechtliche Regelungen</h2>
            <p>
                Berufsrechtliche Regelungen:<br />
                § 34 d Gewerbeordnung (GewO)<br />
                §§ 59 - 68 Versicherungsvertragsgesetz (VVG)<br />
                Versicherungsvermittlerverordnung (VersVermV)<br />
                <br />
                Berufsrechtliche Regelungen für Finanzanlagenvermittler:<br />
                § 34f Gewerbeordnung (GewO)<br />
                Verordnung über die Finanzanlagenvermittlung (FinVermV)<br />
                <br />
                Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="http://www.gesetze-im-internet.de">www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.<br />
            </p>
            <h2>Beschwerdemanagement</h2>
            <p>
                Beschwerden sind in Textform an die Geschäftsleitung zu richten und werden im Rahmen unseres Beschwerdemanagements unverzüglich bearbeitet.
            </p>
        </div>
    </div>
    <Footer/>
    </div>
    );
}

export default Datenschutz;