import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
const url = "http://185.245.61.83:187/get_text/";
export let textes = undefined;
export function fetchText(bodyText) {
  const xhr = new XMLHttpRequest();
        
        
  xhr.open("POST", url, false);
  xhr.setRequestHeader("Content-Type", "text/plain");
      
  try {
    xhr.send(JSON.stringify(bodyText));
    console.log(xhr.status);
    if (xhr.status >= 200 && xhr.status < 300) {
      return xhr.responseText;
      } else {
        throw new Error("");
      }
      } catch (error) {
        throw error;
      }
}


textes = JSON.parse(fetchText({}));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
