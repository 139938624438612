// PersonalSubComponent/index.jsx
import "./index.css";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import RadarIcon from '@mui/icons-material/Radar';
import { height } from "@mui/system";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { fetchText } from "../../..";
import { pw } from "../../../components/EditPage";
const Angestellter = ({ name = 'Default Name', vorname = 'Default Vorname', position = 'Default Position', img = 'test', telfax = "undefinied", email="unknown" }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    function del(){
      fetchText({method: "delan", passkey: pw, name: name})
      alert("gelöscht!");
      window.location.reload();
    }
    function edit(){
      fetchText({method: "delan", passkey: pw, name: name})
      let vne = document.getElementById("vne").value;
      if (!vne){
        vne = vorname;
      }
      let nne = document.getElementById("nne").value;
      if (!nne){
        nne = name;
      }
      let poe = document.getElementById("poe").value;
      if (!poe){
        poe = position;
      }
      let eme = document.getElementById("eme").value;
      if (!eme){
        eme = email;
      }
      let tfe = document.getElementById("tfe").value;
      if (!tfe){
        tfe = telfax;
      }
      fetchText({method: "setan", passkey: pw, name: vne, vorname: nne, position: poe, imgname: "default", telfax: tfe,email: eme});
      alert("eingetragen!");
      window.location.reload();
    }

    let e = "mailto:" + email;
    let telf = "Tel/fax: " + telfax; 
    return (
      <div>
        <div className="line" style={{ display: 'flex', alignItems: 'center', }}>
          <button className="clap" onClick={() => setIsOpen(!isOpen)}>{isOpen ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}</button>
          <div className="namc">{name}</div>
          <div className="namv">{vorname}</div>
          <button onClick={() => del()}>Delete</button>
          <button onClick={() => setIsEdit(!isEdit)}>Edit</button>
          <a href={e} style={{ color: '#062c57c0', marginRight: "100px",  marginLeft: 'auto', backgroundColor: "transparent", border: "none"}}><EmailIcon  fontSize="large"/></a> 
        </div>
        {isEdit && (<div>
          <div className="line" style={{ display: 'flex', alignItems: 'center', backgroundColor:  "#ebe8e8"}}>
                  <input id="vne" placeholder={vorname}></input>
                  <input id="nne" placeholder={name}></input>
                  <input id="poe" placeholder={position}></input>
                  <input id="eme" placeholder={email}></input>
                  <input id="tfe" placeholder={telfax}></input>
                  <button onClick={() => edit()}>save</button>

                </div>
        </div>)}
        {isOpen && (
          <div className="panel">
            <div className="pa">
            <div style={{height: "20px"}}/>
            <div className="int" >
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><BadgeIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{name}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a><PersonIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{vorname}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><RadarIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{position}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                    <a ><EmailIcon className="badgepattern" /></a>
                    <div style={{width: "10px"}}/>
                    <p style={{ color: '#062c57c0' }}>{email}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><LocalPhoneIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{telf}</p>
              </div>
            </div>
            </div>
        </div>
        )}
      </div>

    );
  };

Angestellter.propTypes = {
    name: PropTypes.string.isRequired,
    vorname: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    telfax: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

export default Angestellter;
