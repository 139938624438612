// PersonalSubComponent/index.jsx
import "./index.css";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import RadarIcon from '@mui/icons-material/Radar';
import { height } from "@mui/system";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const Angestellter = ({ name = 'Default Name', vorname = 'Default Vorname', position = 'Default Position', img = 'test', telfax = "undefinied", email="unknown" }) => {
    const [isOpen, setIsOpen] = useState(false);
    let e = "mailto:" + email;
    let telf = "Tel/fax: " + telfax; 
    return (
      <div>
        <div className="line" style={{ display: 'flex', alignItems: 'center', }}>
          <button className="clap" onClick={() => setIsOpen(!isOpen)}>{isOpen ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}</button>
          <div className="namc">{name}</div>
          <div className="namv">{vorname}</div>
          <a href={e} style={{ color: '#062c57c0', marginRight: "100px",  marginLeft: 'auto', backgroundColor: "transparent", border: "none"}}><EmailIcon  fontSize="large"/></a> 
        </div>
        {isOpen && (
          <div className="panel">
            <div className="pa">
            <div style={{height: "20px"}}/>
            <div className="int" >
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><BadgeIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{name}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a><PersonIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{vorname}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><RadarIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{position}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                    <a ><EmailIcon className="badgepattern" /></a>
                    <div style={{width: "10px"}}/>
                    <p style={{ color: '#062c57c0' }}>{email}</p>
              </div>
              <div className="nameline" style={{ display: 'flex', alignItems: 'center' }}>
                <a ><LocalPhoneIcon className="badgepattern" /></a>
                <div style={{width: "10px"}}/>
                <p style={{ color: '#062c57c0' }}>{telf}</p>
              </div>
            </div>
            </div>
        </div>
        )}
      </div>

    );
  };

Angestellter.propTypes = {
    name: PropTypes.string.isRequired,
    vorname: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    telfax: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

export default Angestellter;
