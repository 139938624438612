import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { textes } from "../../index.js"; 
import TextComponent from "../TextComponent.js";
const KontaktBox = () => {
    let headline = textes["kontaktheadline"]
    let kontakttextover = textes["kontakttextover"];
    let subtitlekontakt = textes["subtitlekontakt"];
    let kontaktöff = textes["kontaktöff"];
    let kntsub = textes["kntsub"];
    let subknt = textes["subknt"];
    return (
        <div className="carimagesection" id="carimagesection">
        <div className="placeholder"></div>
        <div className="flytext">
        <div className="withbuttoncontact">
            <p className="kontaktheadline">{headline}</p>
            <p id = "kontakttextover" className="kontakttextover"><TextComponent textWithLineBreaks={kontakttextover}/></p>
			<div className="placeholder2"></div>
            <p className="subtitlekontakt">{subtitlekontakt}</p>
            <p id = "kontaktöff" className="kontakttextover"><TextComponent textWithLineBreaks={kontaktöff}/></p>
			<div className="placeholder2"></div>
            <p className="subtitlekontakt" id = "kntsub">{kntsub}</p>
            <p id = "subknt" className="kontakttextover">
            <TextComponent textWithLineBreaks={subknt}/>
            </p>
            <div className="placeholder"></div>
            <a href="mailto:service@balkgmbh.de" className="emailwritebutton">Email Schreiben</a>
        </div>
      </div>
        <div className="placeholder"></div>
    </div>
    );
}
export default KontaktBox;