// PersonalComponent.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { textes } from "../../index.js"; // Ensure this path is correct
import Angestellter from "./PersonalSubComponent/index.jsx";
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
const PersonalComponent = () => {
    let personallist = [];
    let size = textes["anlistsize"];
    for (let i = 0; i < size; i++) {
        let name = textes[i + ".name"];
        let vorname = textes[i + ".vorname"];
        let position = textes[i + ".position"];
        let img = textes[i + ".imgpath"];
        let telfax = textes[i + ".telfax"];
        let email = textes[i + ".email"]
        personallist.push({name: name, vorname: vorname, position: position, img: "http://185.245.61.83:187/stream/" +img, telfax: telfax, email: email});
    }
    const angeComponents = personallist.map((item, index) => (
        <div key={index}>
          <Angestellter
            name={item.name}
            vorname={item.vorname}
            position={item.position}
            img={item.img}
            email={item.email}
            telfax={item.telfax}
          />
        </div>
      ));

    return (
        <div>
        <div className="balkabout">
        <div className="placeholder"></div>
        <div className="kontakt">
            <div className="balkheadline">Team</div>
                <div className="placeholder"></div>
                <div className = "contactimage"></div>
                <div className="placeholder"></div>
                <div className="line" style={{ display: 'flex', alignItems: 'center', backgroundColor:  "#ebe8e8"}}>
                  <BadgeIcon fontSize="large" style={{ marginLeft: "10"}}/> 
                  <div className="overwrite">Name/Vorname</div>

                  <div className="namv"></div>
                </div>
                {angeComponents}
                <div className="placeholder"></div>
                <div className="placeholder"></div>
                <div className = "contactimage"></div>
                <div className="placeholder"></div>
            </div>

        </div>
    </div>

    );
}

export default PersonalComponent;
